import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { i18n } from '@i18n/i18n';
import { RootState } from '@redux/store';
import {
  BatchDTO,
  BatchDTORecordingTypeEnum,
  Company,
  CompanyPacketWoodQuality,
  CompanyProductType,
  CompanySurface,
  Packet
} from '@services/apis/generated';
import { PacketDTO, PacketDTOCurrentStateEnum } from '@services/apis/generated/models/PacketDTO';
import React, { useEffect, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByTrunkState,
  getTextByTreeOrTrunkSpecies,
  getTextByTrunkState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import { getCardTitleByTrunkNumberFas, getCounterBySelected } from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList
} from '../../../utils/styles/UrstammStyle';

export interface PacketForm {
  packet: PacketDTO;
  packetPhoto?: string;
}

export interface CompanyProductTypeProps {
  companyProductTypeList?: CompanyProductType[];
  resetCompanyProductTypeSelected?: () => void;
  submitCompanyProductType?: (product: CompanyProductType) => void;
  deleteCompanyProductType?: (product: CompanyProductType) => void;
  getMoreProductType?: () => void;
}
export interface CompanyPacketWoodQualityProps {
  companyPacketWoodQualityList?: CompanyPacketWoodQuality[];
  resetCompanyPacketWoodQualitySelected?: () => void;
  submitCompanyPacketWoodQuality?: (product: CompanyPacketWoodQuality) => void;
  deleteCompanyPacketWoodQuality?: (product: CompanyPacketWoodQuality) => void;
  getMorePacketWoodQuality?: () => void;
}
export interface CompanySurfaceProps {
  companySurfaceList?: CompanySurface[];
  resetCompanySurfaceSelected?: () => void;
  submitCompanySurface?: (product: CompanySurface) => void;
  deleteCompanySurface?: (product: CompanySurface) => void;
  getMoreSurface?: () => void;
}

export interface TrunkProps {
  trunkList: any[];
  trunkSelected: (data: any) => void;
  resetTrunkSelected: () => void;
  confirmTrunkSelected: () => void;
  getMoreTrunks?: () => void;
}

export interface CertificationProps {
  certificationList: any[];
  certificationSelected: (data: any) => void;
  resetCertificationSelected: () => void;
  confirmCertificationSelected: () => void;
  getMoreCertifications?: () => void;
}

export interface SpeciesProps {
  speciesList: any[];
  speciesSelected: (data: any) => void;
  resetSpeciesSelected: () => void;
  confirmSpeciesSelected: () => void;
}

export default function PacketRegistrationView(props: {
  navigation: any;
  batchDTO: BatchDTO;
  uniqueIdentifier: string;
  submitPacketForm: (data: PacketForm) => void;
  companyProductTypeProps?: CompanyProductTypeProps;
  companyPacketWoodQualityProps?: CompanyPacketWoodQualityProps;
  companySurfaceProps?: CompanySurfaceProps;
  trunkProps?: TrunkProps;
  certificationProps?: CertificationProps;
  speciesProps?: SpeciesProps;
  processorList: Company[];
  cloneFromPacket?: Packet | PacketDTO;
}) {
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);
  const [uniqueIdentifier, setUniqueIdentifier] = useState<string>(props.uniqueIdentifier);
  const [showProductTypeModalSelect, setShowProductTypeModalSelect] = useState<boolean>(false);
  const [showPacketWoodQualityModalSelect, setShowPacketWoodQualityModalSelect] = useState<boolean>(false);
  const [showSurfaceModalSelect, setShowSurfaceModalSelect] = useState<boolean>(false);
  const [showTrunkModalSelect, setShowTrunkModalSelect] = useState<boolean>(false);
  const [showCertificationModalSelect, setShowCertificationModalSelect] = useState<boolean>(false);
  const [showProcessorModalSelect, setShowProcessorModalSelect] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [trunkList, setTrunkList] = useStateWithCallbackLazy<any>(() => {});
  const [certificationList, setCertificationList] = useStateWithCallbackLazy<any>(() => {});
  const [speciesList, setSpeciesList] = useStateWithCallbackLazy<any>(() => {});
  const [packetProcessorCustomName, setPacketProcessorCustomName] = useState<string>('');
  const [packetProcessorCustomNameReference, setPacketProcessorCustomNameReference] = useState<string>('');
  const [showNoUrstammProcessor, setShowNoUrstammProcessor] = useState<boolean>(false);

  const [previewMultiple, setPreviewMultiple] = useState<string[]>([]); //packet certifications

  const [packetForm, setPacketForm] = useStateWithCallbackLazy<PacketForm>({
    packet: {
      uniqueIdentifier: props.uniqueIdentifier ? props.uniqueIdentifier : '',
      name: props.cloneFromPacket?.name, //
      creationDate: new Date(),
      currentState: PacketDTOCurrentStateEnum.InProgress,
      comment: '',
      length: props.cloneFromPacket?.length, //
      width: props.cloneFromPacket?.width, //
      thickness: props.cloneFromPacket?.thickness, //
      discount: props.cloneFromPacket?.discount, //
      amountOfProducts: props.cloneFromPacket?.amountOfProducts, //
      dryness: props.cloneFromPacket?.dryness, //
      planningDimension: props.cloneFromPacket?.planningDimension, //
      woodQuality: props.cloneFromPacket?.woodQuality, //
      processorCustomName: props.cloneFromPacket?.processorCustomName, //
      processor: props.cloneFromPacket?.processor, //
      packetCertifications: props.cloneFromPacket?.packetCertifications, //
      packetSpecies: props.cloneFromPacket?.packetSpecies, //
      productType: props.cloneFromPacket?.productType, //
      surface: props.cloneFromPacket?.surface, //
      trunks: props.cloneFromPacket?.trunks
    }
  });

  let trunkKeys: ListSubData[] = [
    {
      title: i18n.t('generics.quality') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'quality',
      keyId: '1quality',
      changedKey: 'qualityChanged',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.length') + ' (m):',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'length',
      keyId: '1length',
      changedKey: 'lengthChanged',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.specie') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'species',
      keyId: '1species',
      changedKey: 'speciesChanged',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' },
      isEnum: true
    },
    {
      title: i18n.t('generics.net_volume') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'volume',
      keyId: '1netVolume',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.diameter') + ' (cm):',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'diameter',
      keyId: '1diameter',
      changedKey: 'diameterChanged',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('views.tree.tree_registration.photo_number') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'treePhotoNumber',
      keyId: '1treePhotoNumber',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    }
  ];

  useEffect(() => {
    if (props.uniqueIdentifier) {
      updatePacketForm('uniqueIdentifier', props.uniqueIdentifier);
      setUniqueIdentifier(props.uniqueIdentifier);
    }
    //TODO: clear form
  }, [props.uniqueIdentifier]);

  useEffect(() => {
    if (props.trunkProps?.trunkList) setTrunkList(props.trunkProps?.trunkList, updatedTrunkList => {});
  }, [props.trunkProps?.trunkList]);

  useEffect(() => {
    if (props.certificationProps?.certificationList)
      setCertificationList(props.certificationProps?.certificationList, updatedTrunkList => {});
  }, [props.certificationProps?.certificationList]);

  useEffect(() => {
    if (props.speciesProps?.speciesList) setSpeciesList(props.speciesProps?.speciesList, updatedList => {});
  }, [props.speciesProps?.speciesList]);

  useEffect(() => {
    if (props.cloneFromPacket) {
      saveButtonDisabled(errors, packetForm);
    }
  }, [props.cloneFromPacket]);

  /**
   *
   * @param key
   * @param value
   */
  const updatePacketForm = (key: string, value: any, mustValidate?: boolean): void => {
    let packet = { ...packetForm.packet };

    const formKey = key as keyof any; //TODO: add interface
    packet[formKey] = value;

    return setPacketForm(
      { packet: packet },
      mustValidate ? updatedLoggingForm => validate(value, key, updatedLoggingForm) : () => {}
    );
  };

  //Validators
  const validators = useState<any>({
    name: { required: true },
    length: { numeric: true, max2digits: true },
    width: { numeric_no_digits: true },
    thickness: { numeric_no_digits: true },
    amountOfProducts: { numeric_no_digits: true },
    dryness: { percentage: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: [],
    length: [],
    width: [],
    thickness: [],
    amountOfProducts: [],
    dryness: []
  });

  const validate = (value: string, key: string, updatedTreeForm: PacketForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedPacketForm?: PacketForm) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated) || !updatedPacketForm?.packet.name) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const updateProcessor = (processor: Company) => {
    if (processor.id == -200) {
      setShowProcessorModalSelect(false);
      return setTimeout(() => setShowNoUrstammProcessor(true), 300);
    }
    packetForm.packet.processorCustomName = '';
    updatePacketForm('processor', processor, false);
    return setShowProcessorModalSelect(false);
  };

  const submitForm = () => {
    props.speciesProps?.confirmSpeciesSelected();
    props.submitPacketForm(packetForm);
  };

  const getFilteredProductTypeData = (currentState: string, text: string): any => {
    return props.companyProductTypeProps?.companyProductTypeList?.filter(val =>
      val.name.toLowerCase().includes(text.toLowerCase())
    );
  };

  const getFilteredWoodData = (currentState: string, text: string): any => {
    return props.companyPacketWoodQualityProps?.companyPacketWoodQualityList?.filter(val =>
      val.name.toLowerCase().includes(text.toLowerCase())
    );
  };

  const getFilteredSurfaceData = (currentState: string, text: string): any => {
    return props.companySurfaceProps?.companySurfaceList?.filter(val =>
      val.name.toLowerCase().includes(text.toLowerCase())
    );
  };

  const getFilteredTrunkData = (currentState: string, text: string): any => {
    return props.trunkProps?.trunkList.filter(val => val.name.toLowerCase().includes(text.toLowerCase()));
  };

  const getFilteredPacketData = (currentState: string, text: string): any => {
    return props.processorList.filter(val => val.name?.toLowerCase().includes(text.toLowerCase()));
  };

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn, UrstammStyle.flexStartX]}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** Packet unique identifier - Ex: U_BRU_001_01*/}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="packet_uniqueIdentifier"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={packetForm.packet.uniqueIdentifier ? packetForm.packet.uniqueIdentifier : ''}
            onChangeText={(text: string) => {
              updatePacketForm('uniqueIdentifier', text, true);
            }}
            placeholder={i18n.t('views.packet.packet_registration.insert_packet_unique_identifier_here')}
            textInputTitle={i18n.t('views.packet.packet_registration.unique_identifier')}
            editable={false}
            mandatory={true}
          />

          {/**Validator */}
          {errors['uniqueIdentifier'] && errors['uniqueIdentifier'].length > 0
            ? errors['uniqueIdentifier'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Packet name */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="packet_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={packetForm.packet.name ? packetForm.packet.name : ''}
            onChangeText={(text: string) => {
              updatePacketForm('name', text, true);
            }}
            placeholder={i18n.t('views.packet.packet_registration.insert_packet_name_here')}
            textInputTitle={i18n.t('views.packet.packet_registration.packet_name')}
            mandatory={true}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Product type list */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>{i18n.t('generics.productType')}</Text>
            </View>

            <UrstammButtonBase
              testID={'choose_product_type'}
              text={
                packetForm.packet.productType
                  ? packetForm.packet.productType?.name
                  : i18n.t('views.packet.packet_registration.choose_product_type')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowProductTypeModalSelect(true)}
              //disabled={!batchForm.batch.recordingType}
              //counter={getCounterByDSSelected(deliverySheetList)}
            />

            {/**Packet product type  */}
            {showProductTypeModalSelect ? (
              <UrstammModalSelect
                testID={'modal_packet_company_product_type'}
                visible={showProductTypeModalSelect}
                text={{
                  textInputTitle: i18n.t('views.packet.packet_registration.choose_product_type'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowProductTypeModalSelect(false);
                }}
                //confirmButton={{ enabled: false, text: i18n.t('views.packet.packet_registration.add_new_product'), submitValue: () => { setProductType((prev) => ({ ...prev, name: '', id: undefined, company: undefined, deletionDate: undefined }), (updated) => { setShowProductTypeModalSelect(false), setTimeout(() => setOpenUpdateCreateProductType(true), 300) }) } }}
                listJSX={
                  <UrstammList
                    filterMethod={(currentState: string, text: string) =>
                      getFilteredProductTypeData(currentState, text)
                    }
                    showHash={false}
                    titleKey={'name'}
                    elementSelected={(productType: any) => {
                      updatePacketForm('productType', productType.item, false);
                      setShowProductTypeModalSelect(false);
                    }}
                    testID={'products_type_list'}
                    textForEmptyList={i18n.t('views.packet.products_type_not_found')}
                    list={props.companyProductTypeProps?.companyProductTypeList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    //listSubData={keys}
                    moreItems={props.companyProductTypeProps?.getMoreProductType}
                    filterList={true}
                    multipleSelection={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Packet creation date (preset: today) */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammDatePicker
            testID={'packet_date'}
            containerStyle={UrstammStyleContainer.inputContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            textInputTitle={i18n.t('generics.date')}
            onChange={(dateTime: string) => updatePacketForm('creationDate', dateTime)}
            icon={{
              showIcon: true,
              iconName: 'calendar',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('views.packet.packet_registration.insert_packet_date_here')}
            mandatory={true}
            mode={'date'}
          />
        </View>

        {/** Packet length */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammInput
              testID="length"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={packetForm.packet.length ? packetForm.packet.length.toString() : ''}
              onChangeText={(text: number) => updatePacketForm('length', text, true)}
              placeholder={i18n.t('views.packet.packet_registration.insert_packet_length_here')}
              textInputTitle={i18n.t('views.packet.packet_registration.packet_length')}
              keyboardType={'numeric'}
              maxLength={5}
              mandatory={false}
            />

            {/**Validator */}
            {errors['length'] && errors['length'].length > 0
              ? errors['length'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        ) : null}

        {/** Packet width */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammInput
              testID="width"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={packetForm.packet.width ? packetForm.packet.width.toString() : ''}
              onChangeText={(text: number) => updatePacketForm('width', text, true)}
              placeholder={i18n.t('views.packet.packet_registration.insert_packet_width_here')}
              textInputTitle={i18n.t('views.packet.packet_registration.packet_width')}
              keyboardType={'numeric'}
              mandatory={false}
            />

            {/**Validator */}
            {errors['width'] && errors['width'].length > 0
              ? errors['width'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        ) : null}

        {/** Packet thickness */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammInput
              testID="thickness"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={packetForm.packet.thickness ? packetForm.packet.thickness.toString() : ''}
              onChangeText={(text: number) => updatePacketForm('thickness', text, true)}
              placeholder={i18n.t('views.packet.packet_registration.insert_packet_thickness_here')}
              textInputTitle={i18n.t('views.packet.packet_registration.packet_thickness')}
              keyboardType={'numeric'}
              mandatory={false}
            />

            {/**Validator */}
            {errors['thickness'] && errors['thickness'].length > 0
              ? errors['thickness'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        ) : null}

        {/** Packet amount of products sawmill */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="amountOfProducts"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={
              packetForm.packet.amountOfProducts || packetForm.packet.amountOfProducts == 0
                ? packetForm.packet.amountOfProducts.toString()
                : ''
            }
            onChangeText={(text: number) => updatePacketForm('amountOfProducts', text, true)}
            placeholder={i18n.t('views.packet.packet_registration.insert_amount_of_products_here')}
            textInputTitle={i18n.t('views.packet.packet_registration.amount_of_products')}
            keyboardType={'numeric'}
            mandatory={false}
          />

          {/**Validator */}
          {errors['amountOfProducts'] && errors['amountOfProducts'].length > 0
            ? errors['amountOfProducts'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Packet discount*/}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammInput
              testID="discount"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={packetForm.packet.discount ? packetForm.packet.discount.toString() : ''}
              onChangeText={(text: string) => updatePacketForm('discount', text, false)}
              placeholder={i18n.t('views.packet.packet_registration.insert_packet_discount_here')}
              textInputTitle={i18n.t('views.packet.packet_registration.discount')}
              mandatory={false}
            />
          </View>
        ) : null}

        {/** Packet dryness (in %, +/- 2%) */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammInput
              testID="dryness"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={packetForm.packet.dryness ? packetForm.packet.dryness.toString() : ''}
              onChangeText={(text: number) => updatePacketForm('dryness', text, true)}
              placeholder={i18n.t('views.packet.packet_registration.insert_packet_dryness_here')}
              textInputTitle={i18n.t('views.packet.packet_registration.packet_dryness')}
              keyboardType={'numeric'}
              maxLength={3}
              mandatory={false}
            />
            {/**Validator */}
            {errors['dryness'] && errors['dryness'].length > 0
              ? errors['dryness'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        ) : null}

        {/** Packet Planning dimension (free text field)*/}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammInput
              testID="planning_dimension"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={packetForm.packet.planningDimension ? packetForm.packet.planningDimension.toString() : ''}
              onChangeText={(text: number) => updatePacketForm('planningDimension', text)}
              placeholder={i18n.t('views.packet.packet_registration.insert_packet_planning_dimension_here')}
              textInputTitle={i18n.t('views.packet.packet_registration.packet_planning_dimension')}
              mandatory={false}
            />
          </View>
        ) : null}

        {/** Packet wood quality */}

        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>{i18n.t('generics.wood_quality')}</Text>
            </View>

            <UrstammButtonBase
              testID={'choose_wood_quality'}
              text={
                packetForm.packet.woodQuality
                  ? packetForm.packet.woodQuality?.name
                  : i18n.t('views.packet.packet_registration.choose_wood_quality')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowPacketWoodQualityModalSelect(true)}
              //disabled={!batchForm.batch.recordingType}
              //counter={getCounterByDSSelected(deliverySheetList)}
            />

            {/**Batch Modal multiple select */}
            {showPacketWoodQualityModalSelect ? (
              <UrstammModalSelect
                testID={'modal_packet_company_choose_wood_quality'}
                visible={showPacketWoodQualityModalSelect}
                text={{
                  textInputTitle: i18n.t('views.packet.packet_registration.choose_wood_quality'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowPacketWoodQualityModalSelect(false);
                }}
                listJSX={
                  <UrstammList
                    filterMethod={(currentState: string, text: string) => getFilteredWoodData(currentState, text)}
                    showHash={false}
                    titleKey={'name'}
                    elementSelected={(woodQuality: any) => {
                      updatePacketForm('woodQuality', woodQuality.item, false);
                      setShowPacketWoodQualityModalSelect(false);
                    }}
                    testID={'wood_quality_list'}
                    textForEmptyList={i18n.t('views.packet.woods_quality_not_found')}
                    list={props.companyPacketWoodQualityProps?.companyPacketWoodQualityList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    //listSubData={keys}
                    moreItems={props.companyPacketWoodQualityProps?.getMorePacketWoodQuality}
                    filterList={true}
                    multipleSelection={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/**Packet species */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammSlideButton
              testID={'button_tree_species'}
              slideButtonList={speciesList}
              slideButtonTitle={i18n.t('generics.tree_species')}
              slideButtonRotatedTitle={i18n.t('generics.species')}
              mandatory={false}
              buttonPressed={(selected: SlideButtonOption) => {
                props.speciesProps?.speciesSelected({ item: selected });
                //updatePacketForm('packetSpecies', speciesList, true)
              }}
            />
          </View>
        ) : null}

        {/** Surface list */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>{i18n.t('generics.surface')}</Text>
            </View>

            <UrstammButtonBase
              testID={'choose_surface'}
              text={
                packetForm.packet.surface
                  ? packetForm.packet.surface?.name
                  : i18n.t('views.packet.packet_registration.choose_surface')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowSurfaceModalSelect(true)}
              //disabled={!batchForm.batch.recordingType}
              //counter={getCounterByDSSelected(deliverySheetList)}
            />

            {/**Batch Modal multiple select */}
            {showSurfaceModalSelect ? (
              <UrstammModalSelect
                testID={'modal_packet_company_choose_surface'}
                visible={showSurfaceModalSelect}
                text={{
                  textInputTitle: i18n.t('views.packet.packet_registration.choose_surface'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowSurfaceModalSelect(false);
                }}
                //confirmButton={{ enabled: false, text: i18n.t('views.packet.packet_registration.add_new_product'), submitValue: () => { setSurface((prev) => ({ ...prev, name: '', id: undefined, company: undefined, deletionDate: undefined }), (updated) => { setShowSurfaceModalSelect(false), setTimeout(() => setOpenUpdateCreateSurface(true), 300) }) } }}
                listJSX={
                  <UrstammList
                    filterMethod={(currentState: string, text: string) => getFilteredSurfaceData(currentState, text)}
                    showHash={false}
                    titleKey={'name'}
                    elementSelected={(surface: any) => {
                      updatePacketForm('surface', surface.item, false);
                      setShowSurfaceModalSelect(false);
                    }}
                    testID={'surface_list'}
                    textForEmptyList={i18n.t('views.packet.surfaces_not_found')}
                    list={props.companySurfaceProps?.companySurfaceList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    //listSubData={keys}
                    moreItems={props.companySurfaceProps?.getMoreSurface}
                    filterList={true}
                    multipleSelection={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Trunks list */}
        {props.batchDTO?.recordingType != BatchDTORecordingTypeEnum.Multiple &&
        props.batchDTO?.recordingType != BatchDTORecordingTypeEnum.Date &&
        packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>{i18n.t('views.packet.packet_registration.choose_trunk')}</Text>
            </View>

            <UrstammButtonBase
              testID={'choose_trunk'}
              text={i18n.t('views.packet.packet_registration.choose_trunk')}
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowTrunkModalSelect(true)}
              counter={getCounterBySelected(trunkList)}
            />

            {/**Trunk Modal multiple select */}
            {showTrunkModalSelect ? (
              <UrstammModalSelect
                testID={'modal_trunk_multiple_select'}
                visible={showTrunkModalSelect}
                text={{
                  textInputTitle: i18n.t('views.packet.packet_registration.choose_trunk'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowTrunkModalSelect(false);
                  props.trunkProps?.resetTrunkSelected();
                }}
                confirmButton={{
                  enabled: true,
                  text: i18n.t('generics.save'),
                  submitValue: () => {
                    setShowTrunkModalSelect(false);
                    props.trunkProps?.confirmTrunkSelected();
                  }
                }}
                listJSX={
                  <UrstammList
                    filterMethod={(currentState: string, text: string) => getFilteredTrunkData(currentState, text)}
                    showHash={false}
                    elementSelected={(trunkSelected: any) => props.trunkProps?.trunkSelected(trunkSelected)}
                    testID={'trunk_list'}
                    textForEmptyList={i18n.t('views.trunk.trunks_not_found')}
                    list={props.trunkProps?.trunkList}
                    titleByFunction={data => getCardTitleByTrunkNumberFas(data)}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    listSubData={trunkKeys}
                    moreItems={props.trunkProps?.getMoreTrunks}
                    filterList={true}
                    multipleSelection={true}
                    //filterListFunction={(text, data) => filterDSByNameOrCompany(text, data, props.userExtendedMe!)}
                    currentState={{
                      //icon: (state) => getIconByLoggingState(state),
                      color: state => getColorByTrunkState(state),
                      text: state => getTextByTrunkState(state)
                    }}
                    titleKey={'name'}
                    getTextForData={data => getTextByTreeOrTrunkSpecies(data)}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Certification list */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>
                {i18n.t('views.packet.packet_registration.choose_certification')}
              </Text>
            </View>

            <UrstammButtonBase
              testID={'choose_certification'}
              text={i18n.t('views.packet.packet_registration.choose_certification')}
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowCertificationModalSelect(true)}
              counter={getCounterBySelected(certificationList)}
            />

            {/**Trunk Modal multiple select */}
            {showCertificationModalSelect ? (
              <UrstammModalSelect
                testID={'modal_certification_multiple_select'}
                visible={showCertificationModalSelect}
                text={{
                  textInputTitle: i18n.t('views.packet.packet_registration.choose_certification'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowCertificationModalSelect(false);
                  props.certificationProps?.resetCertificationSelected();
                }}
                confirmButton={{
                  enabled: true,
                  text: i18n.t('generics.save'),
                  submitValue: () => {
                    setShowCertificationModalSelect(false);
                    props.certificationProps?.confirmCertificationSelected();
                  }
                }}
                listJSX={
                  <UrstammList
                    showHash={false}
                    elementSelected={(certificationSelected: any) =>
                      props.certificationProps?.certificationSelected(certificationSelected)
                    }
                    testID={'certification_list'}
                    textForEmptyList={i18n.t('views.packet.certifications_not_found')}
                    list={props.certificationProps?.certificationList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    moreItems={props.certificationProps?.getMoreCertifications}
                    filterList={false}
                    multipleSelection={true}
                    //filterListFunction={(text, data) => filterDSByNameOrCompany(text, data, props.userExtendedMe!)}
                    titleKey={'name'}
                    getTextForData={data => getTextByTreeOrTrunkSpecies(data)}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Processor list */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>{i18n.t('generics.processor')}</Text>
            </View>

            <UrstammButtonBase
              testID={'choose_product_type'}
              text={
                packetForm.packet.processor
                  ? packetForm.packet.processor.name
                  : i18n.t('views.packet.packet_registration.choose_processor')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowProcessorModalSelect(true)}
              //disabled={!batchForm.batch.recordingType}
              //counter={getCounterByDSSelected(deliverySheetList)}
            />

            {/**Packet processor  */}
            {showProcessorModalSelect ? (
              <UrstammModalSelect
                testID={'modal_packet_company_processor'}
                visible={showProcessorModalSelect}
                text={{
                  textInputTitle: i18n.t('views.packet.packet_registration.choose_processor'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowProcessorModalSelect(false);
                }}
                confirmButton={{
                  enabled: false,
                  text: i18n.t('views.packet.no_urstamm_processor'),
                  submitValue: () => {}
                }}
                listJSX={
                  <UrstammList
                    filterMethod={(currentState: string, text: string) => getFilteredPacketData(currentState, text)}
                    showHash={false}
                    titleKey={'name'}
                    elementSelected={(processor: any) => {
                      updateProcessor(processor.item);
                    }}
                    testID={'processor_list'}
                    textForEmptyList={i18n.t('views.packet.processors_not_found')}
                    list={props.processorList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    //listSubData={keys}
                    filterList={true}
                    multipleSelection={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="packet_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={packetForm.packet.comment ? packetForm.packet.comment : ''}
            onChangeText={(text: string) => updatePacketForm('comment', text, false)}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
          />
        </View>

        {/**Save */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_packet'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            onSubmit={() => submitForm()}
            disabled={saveDisabled || rdxLoaderStatus}
          />
        </View>

        {showNoUrstammProcessor ? (
          <UrstammModalSelect
            testID={'modal_no_processor_urstamm'}
            visible={showNoUrstammProcessor}
            text={{
              textInputTitle: i18n.t('views.packet.packet_registration.enter_processor'),
              textStyle: UrstammStyle.textTitle
              //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
            }}
            closeModal={() => {
              setPacketProcessorCustomName(packetProcessorCustomNameReference);
              updatePacketForm('processorCustomName', packetProcessorCustomNameReference, false);
              setShowNoUrstammProcessor(false);
            }}
            modalSize={{ mainContainerWidth: 0.74, mainContainerHeight: 0.4, secondaryContainerHeight: 0.2 }}
            confirmButton={{
              enabled: true,
              text: i18n.t('generics.save'),
              submitValue: () => {
                setPacketProcessorCustomNameReference(packetProcessorCustomName);
                updatePacketForm('processor', { name: packetForm.packet.processorCustomName }, false);
                setShowNoUrstammProcessor(false);
                setShowProcessorModalSelect(false);
              }
            }}
            listJSX={
              <>
                <UrstammInput
                  testID="packet_no_urstamm_processor"
                  style={{
                    containerViewStyle: UrstammStyleContainer.textareaContainer,
                    textStyle: UrstammStyle.formBaseLDarkGrey
                  }}
                  icon={{ showIcon: false }}
                  value={packetForm.packet.processorCustomName ? packetForm.packet.processorCustomName : ''}
                  onChangeText={(text: string) => {
                    updatePacketForm('processorCustomName', text, false);
                    setPacketProcessorCustomName(text);
                  }}
                  placeholder={i18n.t('views.packet.packet_registration.insert_processor_name_address')}
                  textInputTitle={i18n.t('generics.processor')}
                  mandatory={false}
                  multiline={true}
                />
              </>
            }
          />
        ) : null}
      </ScrollView>
    </View>
  );
}
